@font-face {
  font-family: "swiss-normal";
  src: url(/fonts/Swis/swiss-bold.ttf);
}

@font-face {
  font-family: swiss-normal;
  src: url(/fonts/Swis/swiss-normal.ttf);
}

@font-face {
  font-family: RevueBT;
  src: url(/fonts/RevueBT/RevueBT.ttf);
}

@font-face {
  font-family: ReplicaPro;
  src: url(/fonts/ReplicaPro/ReplicaPro.ttf);
}

html,
body {
  width: 100%;
  background-color: #fcfaf7 !important;
  color: #333333 !important;
}

/* Header 1 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "swiss-normal";
}

h2 {
  margin-bottom: 30px;
}

/* Paragraph */
p {
  font-family: ReplicaPro;
}

.synlab-header {
  display: none;
}

section {
  width: 100%;
  height: 100%;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #70707080 !important;
  font-size: 16px;
  font-weight: 400 !important;
  font-family: "swiss-normal";
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin-top: 34px;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe-container {
  width: 360px;
}

/* 575px and down */
@media only screen and (max-width: 575px) {
  /* Body */
  body {
    background-color: #fcfaf7;
    color: #333333;
  }

  /* Links */
  a,
  .nav-link {
    text-decoration: none !important;
    color: #808891 !important;
    font-family: ReplicaPro;
  }

  .nav-link {
    text-decoration: none !important;
    color: #707070 !important;
    font-family: swiss-normal;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("/hamburger.svg") !important;
  }

  .index-header {
    width: 100%;
    height: 40vh;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .index-header-photo {
    width: 100%;
  }

  .index-header-desktop-photo {
    display: none;
  }

  .index-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 100%;
    margin-top: 20px;
  }

  .index-button {
    border: 3px solid rgba(112, 112, 112, 0.2);
    border-radius: 15px;
    background-color: transparent;

    font-family: "swiss-normal";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;

    text-align: center;
    width: 100%;
    margin-top: 15px;
    padding: 5px;

    color: #707070;
  }

  /* Navbar */

  #navbar {
    background-color: #fcfaf7 !important;
  }

  .navbar-light .navbar-toggler {
    color: transparent !important;
    border-color: unset !important;
  }

  /* Index Collaborative Space at the Synlab */
  .collaborative-img {
    width: 50%;
    display: none;
  }

  .collaborative-img-mobile {
    width: 100%;
  }

  /* Project */
  .project {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .current-project-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 34px;
  }

  .search-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    margin-top: 34px;
  }

  .project-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    width: 100%;
    height: 200px;
  }

  .project-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    padding: 10px;
    padding-left: 0px;
  }

  .project-content h3 {
    font-family: "swiss-normal";
    color: #333333;
  }

  .project-content span {
    font-weight: bold;
  }

  .project-individual-heading {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    color: #707070;
    margin-top: 34px;
  }

  .project-individual-paragraph {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #707070;
    margin-top: 34px;
  }

  .project-individual-users {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .people-individual-header {
    margin-top: 65px;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #707070;
  }

  .people-individual-sub-header {
    font-family: swiss-normal;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #707070;
  }

  .people-individual-p {
    font-family: swiss-normal;
    display: block;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: #707070;
    margin-right: auto;
    margin-left: auto;
  }

  .people-individual-profile-project-image {
    display: none;
  }

  .people-individual-papers-header {
    font-size: 20px;
    font-weight: bold;
    font-family: "swiss-normal";
    color: #707070;
  }

  .people-individual-papers-p {
    font-size: 15px;
    font-family: swiss-normal;
    color: #707070;
  }

  .index-video {
    width: 100%;
  }

  /* People Page */
  .people-page-picture-header {
    width: 100%;
    margin-top: 100px;
    border-radius: 45px;
    height: 150px;
  }

  .people-page-header {
    font-size: 35px;
    font-family: "swiss-normal";
    margin-top: 34px;
    text-align: center;
    font-weight: 700;
    color: #707070;
  }

  .people-page-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .people-page-card {
    margin: 10px;
    margin-right: 10px;
    margin-left: 0px;
    width: 130px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .people-page-card h3 {
    color: #707070;
    white-space: nowrap;
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .people-page-card h4 {
    font-size: 10px;
    font-weight: 400;
    font-family: ReplicaPro;
    color: #808790;
    margin: 0px;
    margin-bottom: 5px;
  }

  .people-page-card-image {
    width: 150px;
    height: 160px;

    background-size: cover;
    filter: grayscale(60%);
  }

  .people-page-role-header {
    margin-top: 34px;
    margin-bottom: 0px !important;

    font-family: ReplicaPro;
    color: #707070;
    border-bottom: #70707080 4px solid;
    font-weight: bold;
    font-size: 30px;
  }

  .role {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 100%;
  }

  .role img {
    align-self: baseline;
    justify-self: right;
    padding-left: 5px;
  }

  .search-bar {
    margin-bottom: 0px;
    height: 43px;
    border-radius: 12px !important;
    border: 3px solid #70707080 !important;
    background-color: #fcfaf7 !important;
    font-family: "swiss-normal";
  }

  .question-button {
    background-color: #a8a7a6;
    border-radius: 10px;
    border: none;
    width: 42px;
    height: 43px;
    margin-right: 19px;
    color: #fcfaf7;
    font-weight: bold;
    font-size: 25px;
    font-family: "swiss-normal";

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    cursor: default;
  }

  .question-button .tooltip-text {
    visibility: hidden;
    padding: 0.25em 0.5em;
    background-color: #a8a7a6;
    color: #fcfaf7;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    white-space: nowrap;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 100%;
    transition-property: visibility;
    transition-delay: 0s;
  }

  .question-button:hover .tooltip-text {
    visibility: visible;
    transition-delay: 0.1s;
  }

  /* Individual People's Page */

  .individual-page-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 100px;
  }

  .individual-page-header img {
    width: 50%;
    filter: grayscale(60%);
  }

  .individual-page-header div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .individual-page-header div h1 {
    font-size: 4em;
    font-family: "swiss-normal";

    margin: 0px !important;
  }

  .individual-page-header div h2,
  h3 {
    font-family: ReplicaPro;
    color: #808790;
  }

  .individual-page-role {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    margin-top: 60px;
    margin-bottom: 60px;
  }

  .individual-page-role .member {
    font-family: "swiss-normal";
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .individual-page-contact a p {
    margin: 0px;
  }

  .individual-page-projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: left;
  }

  /* Projects */
  .projects-page {
    display: flex;
    flex-direction: column;
    align-items: left;

    padding-top: 20px;
  }

  .projects-header {
    font-family: ReplicaPro;
    color: #808790;
  }

  .past-project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 225px;
    margin-right: 0px;
  }

  .project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 330px;
    height: 360px;
    flex-wrap: nowrap;
    border-bottom: 4px solid #70707033;
    border-left: 4px solid #70707033;
    border-right: 4px solid #70707033;
    border-radius: 37px;
    margin-right: 0px;
    margin-top: 27px;
  }

  .project-card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    padding: 15px;
  }

  .project-card-info span {
    font-weight: bold;
  }

  .project-card-image {
    width: 300px;
    height: 300px;
    background-size: cover;

    margin-right: 30px;
  }

  .project-year {
    font-family: "swiss-normal";
    color: #808790;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .project-year-bar {
    border-bottom: #9e9e9e 3px solid;
    width: 93%;

    justify-self: center;
    align-self: center;
  }

  .past-projects {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;
  }

  .past-projects h3 {
    font-family: ReplicaPro;
    color: #808790;
  }

  .project-card-past {
    width: 175px;
    height: 175px;
    background-size: cover;

    display: flex;
    flex-direction: column;

    padding: 20px;
    margin: 20px;
    margin-left: 0px;

    box-shadow: inset 0 0 0 2000px rgba(38, 38, 38, 0.71);
  }

  .project-card-past h3 {
    color: white;
    font-size: 1em;
  }

  .project-card-past p {
    color: #808790 !important;
  }

  /* Article/Papers */

  .article {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;
  }

  .article-year {
    font-family: "swiss-normal";
    color: #808790;

    display: flex;
    flex-direction: column;
  }

  .paper-page-header {
    font-family: "swiss-normal";
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .paper-card-container {
    display: flex;
    flex-direction: column;
    margin-top: 34px;
  }

  .article-year-bar {
    border-bottom: #9e9e9e 3px solid;
    width: 100%;
  }

  .articles {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .article-content {
    width: 100%;
  }

  .article-content a h3 {
    font-family: "swiss-normal";
    color: #333333;
  }

  .article-image {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .article-pdf {
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  /* Footer */
  .footer-logos {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    align-content: center;
  }

  .footer-logo {
    height: 75px;
    padding: 15px;
  }

  .footer-text h3 {
    color: #333333 !important;
    font-family: "swiss-normal";
  }

  .footer-text p,
  a {
    text-decoration: none !important;
    color: #333333 !important;
    font-family: "swiss-normal";
  }

  .copyright {
    text-align: center;
  }

  .show-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 100%;
    margin-top: 68px;
    margin-bottom: 68px;
    background-color: transparent;

    font-family: "swiss-normal";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    color: #707070;

    border: 3px solid rgba(112, 112, 112, 0.2);
    border-radius: 36px;
  }
}

@media screen and (max-width: 1050px) {
  .people-individual-profile-project-image {
    display: none;
  }
}

/* 576px and up */
@media only screen and (min-width: 576px) {
  #navbar {
    background-color: #fcfaf7 !important;
  }

  p {
    font-size: 1.6em;
  }

  /* Body */
  .content {
    width: 80% !important;
    margin-bottom: 34px;
  }

  .navbar-none {
    visibility: hidden;
    opacity: 0;
  }

  .navbar-show-no-animation {
    visibility: visible;
    opacity: 1;
  }

  .navbar-show {
    visibility: visible;
    opacity: 1;

    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -ms-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in;
  }

  body {
    background-color: #fcfaf7;
    color: #333333;
  }

  /* Links */
  .nav-list {
    justify-content: flex-end;
    margin-right: 120px;
  }

  .nav-link {
    text-decoration: none !important;
    color: #707070 !important;
    font-family: swiss-normal;
    margin-right: 22px;
    margin-left: 22px;
    font-size: 25px !important;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("/hamburger.svg") !important;
  }

  .index-header-photo {
    display: none;
  }

  #desktop-navbar {
    width: 100%;
    height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .index-header-desktop-photo {
    width: 100%;
  }

  .index-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    width: 100%;
    margin-top: 20px;
  }

  .index-button {
    border: 3px solid rgba(112, 112, 112, 0.2);
    border-radius: 25px;
    background-color: transparent;

    font-family: "swiss-normal";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;

    text-align: center;
    width: 25%;
    margin-top: 15px;
    padding: 15px;

    color: #707070;
  }

  /* Index Collaborative Space at the Synlab */
  .collaborative-img {
    width: 70%;
    float: right;
    padding-left: 5%;
    padding-right: 20%;
  }

  .collaborative-img-mobile {
    display: none;
  }

  /* Project */
  .project {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .current-project-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 68px;
  }

  .search-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    margin-top: 68px;
  }

  .project-img {
    background-size: cover;
    background-position: center;

    width: 500px;
    height: 200px;
    padding: 10px;
  }

  .project-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding-left: 20px;
  }

  .project-content h3 {
    font-family: "swiss-normal";
    color: #333333;
  }

  .project-content p {
    max-width: 33ch;
  }

  .project-content span {
    font-weight: bold;
  }

  .project-individual-heading {
    font-size: 80px;
    font-weight: 700;
    text-align: center;
    color: #707070;
    margin-top: 68px;
  }

  .project-individual-paragraph {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #707070;
    margin-top: 38px;
  }

  .project-individual-users {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;

    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .people-individual-header {
    margin-top: 65px;
    font-size: 80px;
    font-weight: 700;
    text-align: center;
    color: #707070;
  }

  .people-individual-sub-header {
    font-family: swiss-normal;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    color: #707070;
  }

  .people-individual-p {
    font-family: swiss-normal;
    display: block;
    font-size: 25px;
    font-weight: 400;
    text-align: left;
    color: #707070;
    margin-right: auto;
    margin-left: auto;
  }

  .people-individual-profile-project-image {
    width: 270px;
    height: 270px;
    background-size: cover;
    background-position: center;
    border-radius: 25px;
    opacity: 0.5;
  }

  .people-individual-papers-header {
    font-size: 25px;
    font-weight: bold;
    font-family: "swiss-normal";
    color: #707070;
  }

  .people-individual-papers-p {
    font-size: 20px;
    font-family: swiss-normal;
    color: #707070;
  }

  .index-synlab-demo {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .index-video {
    width: 70%;
  }

  .index-video-text {
    width: 100%;
    padding: 10px;
    font-size: 22px;
  }

  /* People Page */
  .people-page-picture-header {
    width: 100%;
    margin-top: 150px;
    border-radius: 45px;
    height: 235px;
  }

  .people-page-header {
    font-size: 50px;
    font-family: "swiss-normal";
    margin-top: 68px;
    text-align: center;
    color: #707070;
  }

  .people-page-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .people-page-card {
    margin: 20px;
    margin-right: 65px;
    margin-left: 0px;
    width: 130px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .people-page-card h3 {
    color: #707070;
    white-space: nowrap;
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .people-page-card h4 {
    font-size: 10px;
    font-weight: 400;
    font-family: ReplicaPro;
    color: #808790;
    margin: 0px;
    margin-bottom: 5px;
  }

  .people-page-card-image {
    width: 150px;
    height: 160px;

    background-size: cover;
    filter: grayscale(60%);
  }

  .people-page-role-header {
    margin-top: 68px;
    margin-bottom: 0px !important;

    font-family: ReplicaPro;
    color: #707070;
    border-bottom: #70707080 4px solid;
    font-weight: bold;
    font-size: 30px;
  }

  .role {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 100%;
  }

  .role img {
    align-self: baseline;
    justify-self: right;
    padding-left: 5px;
  }

  .search-bar {
    margin-bottom: 0px;
    height: 43px;
    border-radius: 12px !important;
    border: 3px solid #70707080 !important;
    background-color: #fcfaf7 !important;
    font-family: "swiss-normal";
  }

  .question-button {
    background-color: #a8a7a6;
    border-radius: 10px;
    border: none;
    width: 42px;
    height: 43px;
    margin-right: 19px;
    color: #fcfaf7;
    font-weight: bold;
    font-size: 25px;
    font-family: "swiss-normal";

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    cursor: default;
  }

  .question-button .tooltip-text {
    visibility: hidden;
    padding: 0.25em 0.5em;
    background-color: #a8a7a6;
    color: #fcfaf7;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    white-space: nowrap;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 100%;
    transition-property: visibility;
    transition-delay: 0s;
  }

  .question-button:hover .tooltip-text {
    visibility: visible;
    transition-delay: 0.1s;
  }

  .show-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 100%;
    margin-top: 68px;
    margin-bottom: 68px;
    background-color: transparent;

    font-family: "swiss-normal";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    color: #707070;

    border: 3px solid rgba(112, 112, 112, 0.2);
    border-radius: 36px;
  }

  /* Individual People's Page */
  .individual-page-header {
    display: flex;
    flex-direction: row;

    margin-top: 100px;
  }

  .individual-page-header img {
    width: 200px;
    height: 200px;
    filter: grayscale(60%);
  }

  .individual-page-header div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding-left: 30px;
  }

  .individual-page-header div h1 {
    font-size: 5em;
    font-family: "swiss-normal";

    margin: 0px !important;
  }

  .individual-page-header div h2,
  h3 {
    font-family: ReplicaPro;
    color: #808790;
  }

  .individual-page-role {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 60px;
    margin-bottom: 60px;
  }

  .individual-page-role .member {
    font-family: "swiss-normal";
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .individual-page-contact a p {
    margin: 0px;
  }

  .individual-page-projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .individual-project-bio {
    font-family: ReplicaPro;
  }

  /* Projects */
  .projects-page {
    display: flex;
    flex-direction: column;
    align-items: left;

    padding-top: 20px;
    margin-left: 100px;
    margin-left: 100px;
  }

  .projects-header {
    font-family: ReplicaPro;
    color: #808790;
    margin-top: 50px;
    margin-bottom: 30px !important;
  }

  .past-project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 225px;
    margin-right: 40px;
  }

  .project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 330px;
    height: 360px;
    flex-wrap: nowrap;
    border-bottom: 4px solid #70707033;
    border-left: 4px solid #70707033;
    border-right: 4px solid #70707033;
    border-radius: 37px;
    margin-right: 27px;
    margin-top: 27px;
  }

  .project-card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding: 15px;
  }

  .project-card-info span {
    font-weight: bold;
  }

  .project-card-image {
    width: 250px;
    height: 150px;
    background-size: cover;

    margin-right: 30px;
  }

  .project-year {
    font-family: "swiss-normal";
    color: #808790;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .project-year-bar {
    border-bottom: #9e9e9e 3px solid;
    width: 93%;

    justify-self: center;
    align-self: center;
  }

  .past-projects {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;
    margin-bottom: 60px;
  }

  .past-projects h3 {
    font-family: ReplicaPro;
    color: #808790;
  }

  .project-card-past {
    width: 250px;
    height: 150px;
    background-size: cover;

    display: flex;
    flex-direction: column;

    padding: 20px;
    margin-top: 10px;
    margin-right: 15px;

    box-shadow: inset 0 0 0 2000px rgba(38, 38, 38, 0.71);
  }

  .project-card-past h3 {
    color: white;
    font-size: 1.2em;
  }

  .project-card-past p {
    color: #808790 !important;
  }

  .page-development {
    width: 100%;
    height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  /* Article/Papers */

  .article {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    display: flex;
    flex-direction: row;
  }

  .article-content {
    width: 100%;
    padding: 30px;
  }

  .paper-page-header {
    font-family: "swiss-normal";
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .paper-card-container {
    display: flex;
    flex-direction: column;
    margin-top: 68px;
  }

  .articles {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .article-content a h3 {
    font-family: "swiss-normal";
    color: #333333;
  }

  .article-image {
    width: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .article-pdf {
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  .article-year {
    font-family: "swiss-normal";
    color: #808790;

    display: flex;
    flex-direction: column;
  }

  .article-year-bar {
    border-bottom: #9e9e9e 3px solid;
    width: 100%;
  }

  .footer-logos {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    align-content: center;
  }

  .footer-logo {
    height: 75px;
    padding: 15px;
  }

  .footer-text h3 {
    color: #333333 !important;
    font-family: "swiss-normal";
    font-size: 2em;
  }

  .footer-text p,
  a {
    text-decoration: none !important;
    color: #333333 !important;
    font-family: "swiss-normal";
    font-size: 1em;
  }

  .copyright {
    text-align: center;
  }
}

@media only screen and (min-width: 1300px) {
  /* Body */
  .content {
    width: 80% !important;
    margin-bottom: 68px;
  }
}

@media only screen and (min-width: 1500px) {
  /* Body */

  .content {
    width: 80% !important;
    margin-bottom: 68px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}
