/* Desktop */

.header {
  margin-bottom: 150px;
}

.landing-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.landing-photo-mobile {
  display: none;
}

.landing-photo-desktop {
  display: block;
  width: 100%;
}

.videoText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 67px;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 50px;
}

.bodyText {
  font-size: 20px;
  color: #707070;
  width: 489px;
}

.hero {
  border: 1px solid #70707080;
  width: 89%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.heroWhite {
  border: 1px solid #fcfaf7;
  width: 89%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.subTitle {
  font-family: "swiss-normal";
  font-size: 30px;
  font-weight: 700;
  line-height: 35.96px;
  text-align: center;
  color: #707070;
}

.socialMediaLogos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.socialMediaLogo {
  width: 75px;
  margin: 35px;
}

.CompanyLogosDisplay {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 94%;
  margin: auto;
}

.companyLogos {
  width: 100px;
  margin: 15px;
}

.footer {
  width: 100%;
  background-color: #f27e59;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footerText {
  color: #fcfaf7;
  font-size: 40px;
}

.sub-footer-text {
  color: #fcfaf7;
  font-family: swiss-normal;
  font-size: 20px;
  text-align: center;
}

@media (min-width: 1024px) {
  .header {
    margin-bottom: 150px;
  }

  .landing-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .landing-photo-desktop {
    display: block;
    width: 100%;
  }

  .videoText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 67px;
    flex-wrap: nowrap;
    width: 100%;
  }

  .bodyText {
    font-size: 20px;
    color: #707070;
    width: 489px;
  }

  .hero {
    border: 1px solid #70707080;
    width: 89%;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .heroWhite {
    border: 1px solid #fcfaf7;
    width: 89%;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .subTitle {
    font-family: "swiss-normal";
    font-size: 30px;
    font-weight: 700;
    line-height: 35.96px;
    text-align: center;
    color: #707070;
  }

  .socialMediaLogos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }

  .socialMediaLogo {
    width: 75px;
    margin: 35px;
  }

  .CompanyLogosDisplay {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 94%;
    margin: auto;
  }

  .companyLogos {
    width: 100px;
    margin: 15px;
  }

  .footer {
    width: 100%;
    background-color: #f27e59;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 20px;
  }

  .footerText {
    font-size: 40px;
    color: #fcfaf7;
  }

  .sub-footer-text {
    color: #fcfaf7;
    font-family: swiss-normal;
    font-size: 20px;
    text-align: center;
  }
}

/* Mobile */
@media (max-width: 992px) {
  .header {
    margin-bottom: 100px;
  }

  .landing-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .landing-photo-mobile {
    display: block;
  }

  .landing-photo-desktop {
    display: none;
  }

  .videoText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 0px;
  }

  .bodyText {
    font-size: 15px;
    color: #707070;
    margin-top: 20px;
  }

  .hero {
    border: 1px solid #70707080;
    width: 89%;
    margin-top: 33px;
    margin-bottom: 33px;
  }

  .heroWhite {
    border: 1px solid #fcfaf7;
    width: 89%;
    margin-top: 33px;
    margin-bottom: 33px;
  }

  .subTitle {
    font-family: "swiss-normal";
    font-size: 30px;
    font-weight: 700;
    line-height: 35.96px;
    text-align: center;
    color: #707070;
  }

  .socialMediaLogos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-wrap: wrap;
    width: 100%;
  }

  .socialMediaLogo {
    width: 75px;
    margin: 35px;
  }

  .CompanyLogosDisplay {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 89%;
  }

  .companyLogos {
    width: 80px;
    margin: 15px;
  }

  .footer {
    width: 100%;
    background-color: #f27e59;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .footerText {
    color: #fcfaf7;
    flex-wrap: wrap;
    text-align: center;
    font-size: 30px;
  }

  .sub-footer-text {
    color: #fcfaf7;
    font-family: swiss-normal;
    font-size: 15px;
    text-align: center;
  }
}
